<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">My Performance Plan</h3>
        <div>
          <el-select v-model="selectedEvalPeriod" @change="getMPPData(), getMppCoreData(), getCoreValue(), getOverallMppCoreData(),  getMppGrowthData()" placeholder="Select Evaluation Period" size="mini" value-key="id">
            <el-option v-for="mpp in mppList" :key="mpp.id" :value="mpp" :label="mpp.name"></el-option>
          </el-select>
        </div>
      </div>
      <el-tabs v-model="activeName" v-if="viewContent">
        <el-tab-pane label="Individual" name="Individual">
          <el-row el-row type="flex" justify="space-between">
            <el-col :sm="8" :md="5" :lg="2" :xl="5">
              <el-progress type="circle" :percentage="computeProgressBar()" :width="80"></el-progress>
            </el-col>
            <el-col>
              <el-button style="background:#EB2C3E; float:right; color:white;" size="mini" @click="submitMPP" :disabled="checkSubmit()">Submit</el-button>
            </el-col>
          </el-row>
          <el-collapse v-for="(parentItem, parentIndex) in tableData" :key="parentIndex" v-model="financialExpansion">
            <el-collapse-item :title="parentItem.category_bg4_id_name + ' - ' + parentItem.weight + '%'" :name="parentItem.category_bg4_id_name">
              <!-- <hr> -->
              <div v-for="(childItem, childIndex) in parentItem.perspective" :key="childIndex">
                <h1 style="font-size:13px !important;">{{childItem.perspective_bg4_id_name}}</h1>
                  <div v-for="(grandChildItem, grandChildIndex) in childItem.strategic_objectives" :key="grandChildIndex">
                    <h2>{{grandChildItem.objective_bg4_id_name}}</h2>
                    <el-table
                      :data="grandChildItem.attributes"
                      style="width: 100%">
                      <el-table-column
                        prop="weight"
                        label="% Weight"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.weight}}</span>
                          <el-input v-model="scope.row.weight" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="measurement_bg4_id_name"
                        label="Strategic Measurements (KPI)"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.measurement_bg4_id_name}}</span>
                          <el-input v-model="scope.row.measurement_bg4_id_name" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="threshold"
                        label="Threshold"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.threshold}}</span>
                          <el-input v-model="scope.row.threshold" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="target"
                        label="Target"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.target}}</span>
                          <el-input v-model="scope.row.target" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="superior"
                        label="Superior"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.superior}}</span>
                          <el-input v-model="scope.row.superior" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="actual"
                        label="Actual"
                        >
                        <!-- <template slot-scope="scope">
                          <span v-if="!scope.row.isEdit">{{scope.row.actual}}</span>
                          <el-input v-model="scope.row.actual" v-else></el-input>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        prop="rating"
                        label="Rating"
                        >
                        <template slot-scope="scope">
                          <div v-if="scope.row.employee_document_mpp_bsc">
                            <span v-if="!scope.row.isEdit">{{scope.row.employee_document_mpp_bsc.rating}}</span>
                            <el-input v-model="scope.row.employee_document_mpp_bsc.rating" v-else type="number"></el-input>
                          </div>
                          <span v-else>
                            -
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        fixed="right"
                        label="Action"
                        >
                        <template slot-scope="scope">
                          <div v-if="scope.row.employee_document_mpp_bsc">
                            <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                              <el-button type="text" :disabled="scope.row.employee_document_mpp_bsc.a_id === '8' || scope.row.employee_document_mpp_bsc.is_self_rate === '0'" @click="edit(parentIndex, childIndex, grandChildIndex, scope.$index, true)"><i class="el-icon-edit"></i></el-button>
                            </el-tooltip>
                            <el-tooltip content="Confirm Edit" placement="top" v-else>
                              <el-button type="text" @click="updateRating(scope.row)"><i class="el-icon-check"></i></el-button>
                            </el-tooltip>
                          </div>
                          <span v-else>
                            -
                          </span>
                          <!-- <el-tooltip content="Delete" placement="top" v-if="scope.$index !== 0">
                            <el-button type="text" @click="remove(parentIndex, childIndex, grandChildIndex, scope.$index)"><i class="el-icon-delete"></i></el-button>
                          </el-tooltip> -->
                        </template>
                      </el-table-column>
                    </el-table>
                    <h3>Sub-Total: {{computeTotal(grandChildItem)}}</h3>
                  </div>
                <!-- </el-collapse-item> -->
                <hr>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="Core Values" name="Core Values">
          <el-form :model="addForm" ref="addForm" size="mini" label-position="top">
            <el-row :gutter="24" style="padding-top: 20px;">
              <el-col :md="5" :lg="5" :xs="24" :sm="24">
                <el-form-item label="Core Values" prop="selectedCoreValues">
                  <el-select v-model="addForm.selectedCoreValues" placeholder="Core Values">
                    <el-option v-for="core in coreList" :key="core.id" :value="core.id" :label="core.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="5" :lg="5" :xs="26" :sm="26">
                <el-form-item label="Achievement:" prop="achievement">
                  <el-input v-model="addForm.achievement">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="5" :lg="5" :xs="26" :sm="26">
                <el-form-item label="Development:" prop="development">
                  <el-input type="text" v-model="addForm.development">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="5" :lg="5" :xs="24" :sm="24">
                <el-form-item label="Rating:" prop="rating">
                  <el-select v-model="addForm.rating" placeholder="Rating">
                    <el-option v-for="rating in ratingList" :key="rating.id" :value="rating.id" :label="rating.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="3" :lg="3" :xs="24" :sm="24" v-if="!isEdit">
                <el-button style="float:right;" size="large" @click="submitCoreValue(0)">Add</el-button>
              </el-col>
            </el-row>
          </el-form>
          <el-collapse v-for="(parentItem, parentIndex) in coreData" :key="parentIndex" v-model="coreExpansion">
            <div v-if="parentItem.table_core == 0">
              <el-collapse-item :title="parentItem.core_values_name" :name="parentItem.core_values_name">
                  <el-table :data="parentItem.employee_document_mpp_corevalues_items" style="width: 100%">
                        <el-table-column prop="achievement" label="Achievement"></el-table-column>
                        <el-table-column prop="development" label="Development"></el-table-column>
                        <el-table-column prop="rating" label="Rating"></el-table-column>
                        <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
                          <template slot-scope="scope">
                            <!-- <el-button-group>
                              <el-button type="text" @click="updateCoreRating(scope.row,parentItem.id)"><i class="el-icon-check"></i></el-button>
                            </el-button-group> -->
                            <el-tooltip content="Delete" placement="top" v-if="!scope.row.isEdit">
                              <el-button type="text" @click="deleteThis(parentItem.id)" ><i class="el-icon-delete"></i></el-button>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                  </el-table>
              </el-collapse-item>
            </div>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="Leadership Core Values" name=" Leadership Core Values">
          <div v-if="tableData[0].perspective[0].strategic_objectives[0].attributes[0].employee_document_mpp_bsc.is_teamlead == 1">
            <el-form :model="addForm" ref="addForm" size="mini" label-position="top">
              <el-row :gutter="24" style="padding-top: 20px;">
                <el-col :md="5" :lg="5" :xs="24" :sm="24">
                  <el-form-item label="Leadership Core Values" prop="selectedCoreValues">
                    <el-select v-model="addForm.selectedCoreValues" placeholder="Leadership Core Values">
                      <el-option v-for="core in coreList" :key="core.id" :value="core.id" :label="core.name"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="5" :lg="5" :xs="26" :sm="26">
                  <el-form-item label="Achievement:" prop="achievement">
                    <el-input v-model="addForm.achievement">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="5" :lg="5" :xs="26" :sm="26">
                  <el-form-item label="Development:" prop="development">
                    <el-input type="text" v-model="addForm.development">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="5" :lg="5" :xs="24" :sm="24">
                  <el-form-item label="Rating:" prop="rating">
                    <el-select v-model="addForm.rating" placeholder="Rating">
                      <el-option v-for="rating in ratingList" :key="rating.id" :value="rating.id" :label="rating.name"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="3" :lg="3" :xs="24" :sm="24" v-if="!isEdit">
                  <el-button style="float:right;" size="large" @click="submitCoreValue(1)">Add</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-collapse v-for="(parentItem, parentIndex) in coreData" :key="parentIndex" v-model="coreExpansion">
            <div v-if="parentItem.table_core == 1">
              <el-collapse-item :title="parentItem.core_values_name" :name="parentItem.core_values_name">
                  <el-table :data="parentItem.employee_document_mpp_corevalues_items" style="width: 100%">
                      <el-table-column prop="achievement" label="Achievement"></el-table-column>
                      <el-table-column prop="development" label="Development"></el-table-column>
                      <el-table-column prop="rating" label="Rating"></el-table-column>
                      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
                        <template slot-scope="scope">
                          <!-- <el-button-group>
                            <el-button type="text" @click="updateCoreRating(scope.row,parentItem.id)"><i class="el-icon-check"></i></el-button>
                          </el-button-group> -->
                          <el-tooltip content="Delete" placement="top" v-if="!scope.row.isEdit">
                            <el-button type="text" @click="deleteThis(parentItem.id)" ><i class="el-icon-delete"></i></el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                  </el-table>
              </el-collapse-item>
            </div>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="Overall Performance" name="Overall Performance">
          <el-table :data="overallData" border max-height="580">
            <el-table-column prop="performance_rating" label="Performance Factor"></el-table-column>
            <el-table-column prop="rating" label="Rating"></el-table-column>
            <el-table-column prop="final_rating" label="Final Rating"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Growth Plan" name="Growth Plan">
          <el-form :model="addGrowthForm" ref="addGrowthForm" size="large" label-position="top">
            <el-row :gutter="24" style="padding-top: 20px;">
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Key Performance Area:" prop="key_performance_area">
                  <el-input type="textarea" v-model="addGrowthForm.key_performance_area">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Development Needs:" prop="development_need">
                  <el-input type="textarea" v-model="addGrowthForm.development_need">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Action Plan:" prop="action_plan">
                  <el-input type="textarea" v-model="addGrowthForm.action_plan">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Target Completion Date:" prop="target_date">
                  <el-input type="textarea" v-model="addGrowthForm.target_date">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Status:" prop="status">
                  <el-input type="textarea" v-model="addGrowthForm.status">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4" :lg="4" :xs="24" :sm="24">
                <el-form-item label="Remarks:" prop="remarks">
                  <el-input type="textarea" v-model="addGrowthForm.remarks">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="24" :xs="24" :sm="24">
                <el-button style="float:right;" size="large" @click="submitGrowth">Add</el-button>
              </el-col>
            </el-row>
          </el-form>
          <el-table :data="growthData" border max-height="580">
            <el-table-column prop="key_performance_area" label="Key Performance Areas"></el-table-column>
            <el-table-column prop="development_need" label="Development Needs"></el-table-column>
            <el-table-column prop="action_plan" label="Action Plan"></el-table-column>
            <el-table-column prop="target_date" label="Target Completion Date"></el-table-column>
            <el-table-column prop="status" label="Status"></el-table-column>
            <el-table-column prop="remarks" label="Remarks"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  created () {
    // this.getMPPData()
    this.getEvalList()
    // var cloneData = [...this.tableData]
    // this.finalData = cloneData
  },
  data () {
    return {
      finalData: [],
      financialExpansion: [],
      coreExpansion: [],
      tableData: [],
      coreData: [],
      coreValueData: [],
      coreList: [],
      ratingList: [
        {
          id: 1,
          name: 1
        },
        {
          id: 2,
          name: 2
        },
        {
          id: 3,
          name: 3
        },
        {
          id: 4,
          name: 4
        },
        {
          id: 5,
          name: 5
        }
      ],
      selectedCoreValues: '',
      addForm: {
        achievement: '',
        development: '',
        rating: '',
        selectedCoreValues: ''
      },
      addGrowthForm: {
        key_performance_area: '',
        development_need: '',
        action_plan: '',
        target_date: '',
        status: '',
        remarks: ''
      },
      overallData: [],
      growthData: [],
      // tableData: [
      //   {
      //     id: 1,
      //     weight: 5.00,
      //     category_bg4_id_name: 'Financial',
      //     perspective: [
      //       {
      //         id: 1,
      //         hemedbi1_id: 1,
      //         weight: 5.00,
      //         perspective_bg4_id_name: 'Financial Performance',
      //         strategic_objectives: [
      //           {
      //             id: 1,
      //             hemedbi2_id: 1,
      //             weight: 5.00,
      //             objective_bg4_id_name: 'Attain employee capabilities',
      //             attributes: [
      //               {
      //                 id: 1,
      //                 hemedbi3_id: 1,
      //                 measurement_bg4_id_name: 'Breaches - No high and medium breach',
      //                 weight: 2.50,
      //                 threshold: null,
      //                 target: null,
      //                 superior: null,
      //                 actual: 5.00,
      //                 points: 5.00,
      //                 rating: 5.00
      //               },
      //               {
      //                 id: 2,
      //                 hemedbi3_id: 1,
      //                 measurement_bg4_id_name: 'Cost of Service - Cost Revenue Ratio Budget Utilization & Control',
      //                 weight: 5.00,
      //                 threshold: null,
      //                 target: '11M',
      //                 superior: '12M',
      //                 actual: 5.00,
      //                 points: 5.00,
      //                 rating: 5.00
      //               }
      //             ]
      //           },
      //           {
      //             id: 1,
      //             hemedbi2_id: 1,
      //             weight: 5.00,
      //             objective_bg4_id_name: 'Sample 2',
      //             attributes: [
      //               {
      //                 id: 1,
      //                 hemedbi3_id: 1,
      //                 measurement_bg4_id_name: 'Breaches - No high and medium breach',
      //                 weight: 2.50,
      //                 threshold: null,
      //                 target: null,
      //                 superior: null,
      //                 actual: 5.00,
      //                 points: 5.00,
      //                 rating: 5.00
      //               },
      //               {
      //                 id: 2,
      //                 hemedbi3_id: 1,
      //                 measurement_bg4_id_name: 'Cost of Service - Cost Revenue Ratio Budget Utilization & Control',
      //                 weight: 5.00,
      //                 threshold: null,
      //                 target: '11M',
      //                 superior: '12M',
      //                 actual: 5.00,
      //                 points: 5.00,
      //                 rating: 5.00
      //               }
      //             ]
      //           }
      //         ]
      //       },
      //       {
      //         id: 2,
      //         hemedbi1_id: 1,
      //         weight: 5.00,
      //         perspective_bg4_id_name: 'Governance',
      //         strategic_objectives: []
      //       }
      //     ]
      //   }
      // ],
      dialogApproverVisible: false,
      dialogView: false,
      dialogNPAView: false,
      drawerComments: false,
      comments: [],
      approvers: [],
      data: [],
      showApproval: false,
      npa: null,
      document: null,
      forApproval: {},
      activeName: 'Individual',
      selectedEvalPeriod: '',
      mppList: [],
      viewContent: false,
      totalAttributes: 0,
      completedAttributes: 0,
      submitted: false
    }
  },
  methods: {
    checkSubmit () {
      var computation = Math.round(((this.completedAttributes / this.totalAttributes) * 100))
      if (computation && computation === 100 && this.submitted === false) {
        return false
      } else {
        return true
      }
    },
    computeProgressBar () {
      var computation = Math.round(((this.completedAttributes / this.totalAttributes) * 100))
      if (computation) {
        return computation
      } else {
        return '-'
      }
    },
    submitMPP () {
      var formSubmit = {}
      formSubmit.id = this.selectedEvalPeriod.edm_id
      formSubmit.a_id = 8
      this.$http
        .post('mpp-employee-update-status', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.getMPPData()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    getEvalList () {
      this.$http
        .get('mpp-completed-evaluation-list-by-em-id/' + this.$store.getters.user.employeeId)
        .then(res => {
          console.log(res)
          this.mppList = res.data.body
        })
    },
    handleChange () {
      this.$http
        .get('mpp-employee-list/' + this.selectedEvalPeriod.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {

          } else {
            console.log(res)
          }
        })
    },
    submitCoreValue (table) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.core_value_bg4_id = this.addForm.selectedCoreValues
          formSubmit.hme_id = this.selectedEvalPeriod.id
          formSubmit.weight = 0
          formSubmit.rating = this.addForm.rating
          formSubmit.achievement = this.addForm.achievement
          formSubmit.development = this.addForm.development
          formSubmit.table_core = table
          this.$http
            .post('mpp-employee-update-core-value-rating', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getMppCoreData()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
        }
      })
    },
    submitGrowth () {
      this.$refs.addGrowthForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.hme_id = this.selectedEvalPeriod.id
          formSubmit.key_performance_area = this.addGrowthForm.key_performance_area
          formSubmit.development_need = this.addGrowthForm.development_need
          formSubmit.action_plan = this.addGrowthForm.action_plan
          formSubmit.target_date = this.addGrowthForm.target_date
          formSubmit.status = this.addGrowthForm.status
          formSubmit.remarks = this.addGrowthForm.remarks
          this.$http
            .post('mpp-employee-growth-plan', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getMppGrowthData()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
        }
      })
    },
    updateRating (item) {
      if (item.employee_document_mpp_bsc.rating !== '' && item.employee_document_mpp_bsc.rating !== 'e') {
        var formSubmit = {}
        formSubmit.id = item.employee_document_mpp_bsc.edmb_id
        formSubmit.edm_id = item.employee_document_mpp_bsc.edm_id
        formSubmit.rating = item.employee_document_mpp_bsc.rating
        this.$http
          .post('mpp-employee-update-rating', formSubmit)
          .then(res => {
            if (res.data.StatusCode) {
              this.$message({
                showClose: true,
                message: 'Form Submitted',
                type: 'success'
              })
              this.getMPPData()
              this.getOverallMppCoreData()
            } else {
              this.$message({
                showClose: true,
                message: res.data.body,
                type: 'error'
              })
            }
          })
      } else {
        this.$message({
          showClose: true,
          message: 'Please enter a Rating before submitting!',
          type: 'error'
        })
      }
    },
    getMppCoreData () {
      this.$http
        .get('mpp-employee-core-value/' + this.selectedEvalPeriod.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
          } else {
            this.coreData = res.data.body
            res.data.body.forEach(e => {
              this.coreValueData = e.employee_document_mpp_corevalues_items
            })
          }
        })
    },
    getOverallMppCoreData () {
      this.$http
        .get('mpp-overall-performance/' + this.selectedEvalPeriod.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
          } else {
            this.overallData = res.data.body
          }
        })
    },
    getMppGrowthData () {
      this.$http
        .get('mpp-employee-growth-plan/' + this.selectedEvalPeriod.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
          } else {
            this.growthData = res.data.body
          }
        })
    },
    getMPPData () {
      this.submitted = false
      this.completedAttributes = 0
      this.totalAttributes = 0
      this.$http
        .get('mpp-employee-bsc/' + this.selectedEvalPeriod.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
          } else {
            console.log(res, 'hello')
            res.data.body.forEach(e => {
              e.perspective.forEach(f => {
                f.strategic_objectives.forEach(g => {
                  this.totalAttributes += g.attributes.length
                  g.attributes.forEach(h => {
                    // var clone = { ...h }
                    // clone.isEdit = false
                    if (h.employee_document_mpp_bsc) {
                      if (h.employee_document_mpp_bsc.a_id === '8') {
                        this.submitted = true
                      }
                      if (h.employee_document_mpp_bsc.rating !== '.00') {
                        this.completedAttributes++
                      }
                    }
                    this.$set(h, 'isEdit', false)
                    // h.isEdit = JSON.parse(false)
                  })
                })
              })
            })
            this.viewContent = true
            this.tableData = res.data.body
          }
        })
    },
    computeTotal (item) {
      var total = 0
      item.attributes.forEach(e => {
        total += parseFloat(e.weight)
      })
      if (isNaN(total)) {
        return ''
      }
      return total.toFixed(1) + '%'
    },
    edit (parentIndex, childIndex, grandChildIndex, tableIndex, action) {
      this.tableData[parentIndex].perspective[childIndex].strategic_objectives[grandChildIndex].attributes[tableIndex].isEdit = action
    },
    remove (parentIndex, childIndex, grandChildIndex, tableIndex) {
      console.log('hello')
      this.tableData[parentIndex].perspective[childIndex].strategic_objectives[grandChildIndex].attributes.splice(tableIndex, 1)
    },
    getCoreValue () {
      this.$http
        .get('backend.global4-list/101')
        .then(res => {
          this.coreList = res.data.body
        })
    },
    deleteThis (id) {
      this.$http
        .delete('mpp-employee-core-value-delete/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            // this.perspectives = []
          } else {
            this.getMppCoreData()
          }
        })
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
